// Adapted from: https://www.youtube.com/watch?v=7WwtzsSHdpI

@import "../../global.scss";

.contact {
    display: flex;
    background-color: #fff;

    @include mobile{
        flex-direction: column;
      }

    .left {
        flex: 1;
        overflow: hidden;

        img {
            height: 100%;
        }
    }

    .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;

        h2 {
            font-size: 50px;
            color: black;
            margin-bottom: 5px;

            @include mobile{
                font-size: 30px;
              }
        }

        form {
            width: 70%;
            height: 60%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            input {
                width: 300px;
                height: 30px;
                font-size: 14px;

                @include mobile{
                    width: 200px;
                    height: 20px;
                    margin: 5px;
                  }
            }

            input:focus, textarea:focus {
                border: 3px solid $blueShade;
                outline: none;
            }

            textarea {
                width: 300px;
                height: 200px;
                font-size: 14px;
                resize: none;

                @include mobile {
                    width: 200px;
                    height: 100px;
                    margin-bottom: 5px;
                  }
            }

            button {
                width: 150px;
                height: 30px;
                color: white;
                background-color: $blueShade;
                border: none;
                border-radius: 10px;
                font-weight: 500;
                cursor: pointer;

                &:focus {
                    outline: none;
                  }
            }

            span {
                color: $blueShade;
              }
        }
    }
}