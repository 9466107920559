// Adapted from: https://www.youtube.com/watch?v=7WwtzsSHdpI

$mainColor: #000;
$blueShade: #4b65ff;

$width: 758px;

@mixin mobile {
    @media (max-width: #{$width}) {
        @content
    }
}