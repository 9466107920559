.app {
    height: 100vh;

    .sections {
        width: 100%;
        height: calc(100vh - 70px);
        background-color: magenta;
        position: relative;
        top: 70px;
        // change speed of scroll when clicking on anchor
        scroll-behavior: smooth;

        // snap scrolling
        scroll-snap-type: y mandatory;
        scrollbar-width: none; // for firefox
        &::-webkit-scrollbar { // for other browsers
            display: none;
        }

        > * {
            width: 100vw;
            height: calc(100vh - 70px);
            // snap scrolling
            scroll-snap-align: start;
        }
    }

}